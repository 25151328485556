.clientOverview {
    .partone {
        height: auto;
        padding-top: 3vh;
        padding-left: 6vw;
        padding-bottom: 5vh;
        padding-right: 4vw;
        overflow: hidden;
    }
    .templateTiles {
        width: 19.88vw;
        height: 31.17vh;
        padding: 2vh 2vw;
        // background: #ffefd1;
    }
    .templateBg {
        background-color: #e6f2f9 !important;
        border: 1px solid #0679c3 !important;
        width: 19.88vw;
    }
    .partonetitle {
        font-weight: 500 !important;
        line-height: 4 !important;
        font-size: 2.5vh !important;
    }
    .columngap {
        gap: 0.8vh !important;
        .columngaptext {
            font-weight: 400 !important;
            font-size: 2.23vh !important;
        }
    }
    .addusergap {
        margin-top: 2vh !important;
    }
    .rowGap {
        gap: 5vw !important;
    }
    .genericLink {
        position: absolute;
        // left: 51vw;
        // top: 91vh;
        height: 10vh;
        width: 10vw;
    }
    .individuallink {
        position: absolute;
        margin-left: 15vw;
        margin-top: -4vh;
        height: 10vh;
        width: 10vw;
    }
    .linkClass {
        // padding: 2vh 2vh;
        // width: 18vw;
        // height: 20vh;
    }
    .linkContent {
        padding-left: 2vw;
        margin-top: 0.5vh;
        width: 12vw;
    }
    .linkText {
        font-size: 2.23vh !important;
        line-height: 3.34vh !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }
    .img {
        height: 2.85vh;
        width: 1.39vw;
    }
    .linkTypeContent {
        font-size: 2.23vh !important;
        font-weight: 400 !important;
        line-height: 3.34vh !important;
        color: #757575 !important;
    }
    .radioimg {
        height: 2.83vh;
        width: 1.39vw;
    }
    .rowgaptype {
        gap: 1vw !important;
    }
    .linktyperow {
        gap: 5vw !important;
        align-items: flex-start !important;
    }
    .imgTemplate {
        height: 3.26vh;
        width: 1.6vw;
    }
    .wordingClass {
        line-height: 2.83vh !important;
        font-size: 1.98vh !important;
        font-weight: 300 !important;
    }
    .rowGapTab {
        gap: 3vw !important;
    }
}
.nonprofit {
    background-color: #0679c3;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.text {
    font-size: 1.7vh !important;
    font-weight: 500 !important;
    line-height: 2.26vh !important;
}
