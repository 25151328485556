.warningDialog {
    position: relative;

    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(4px);
    }

    .MuiDialog-paper {
        border-radius: 12px;
        max-width: 600px;
        background-color: #fbdedb;
        border: 1px solid #ff3b4c;
        color: #ad005b;

        h2 {
            padding: 8px 32px 12px;
            font-size: 16px;
            font-weight: 500;
            margin: 0;

            span {
                margin-left: 4px;
                color: #148fa7;
                cursor: pointer;
            }
        }
    }
}
