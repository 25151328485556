.monitor-section {
    .headerContainer {
        margin-right: -4vw;
        margin-left: -3vw;
        padding-left: 3vw;
        box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    }
    .titleContainer {
        padding-top: 3vh;
        padding-bottom: 1.68vh;
    }
    .titleClass {
        line-height: 5.04vh !important;
    }
}

.chartWrapper {
    position: relative;
    width: 100%;
}

.chartWrapper > canvas {
    background-color: white;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: -1;
}

.chartAreaWrapper {
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 20px;
}

.chartAreaWrapper2 {
    height: 335px;
    width: 100%;
}
