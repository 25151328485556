.typography {
    font-size: 2.55vh !important;
    font-weight: 500 !important;
}
.flexColgap {
    padding: 0;
    gap: 3.83vh;
}
.changeFlexContent {
    justify-content: center;
    padding: 0;
}
.surveyData {
    font-size: 2.26vh !important;
    font-weight: 400 !important;
    color: #333333 !important;
}
.text-deco {
    text-decoration: underline;
    color: #2b5580 !important;
}

.pie-parent {
    position: absolute;
    margin-left: 15vw;
    margin-top: 3vh;
    .pie {
        color: #ffefd1 !important;
        height: 7.8vh !important;
        width: 7.8vw !important;
    }
    .pie-value {
        color: #ffa800 !important;
        height: 7.8vh !important;
        width: 7.8vw !important;
        margin-left: -7.8vw;
    }
  
}

.rateClass {
    font-size: 1.5vh;
    .img1 {
        height: 1.4vh;
        width: 1.4vh;
        border-radius: 50%;
        margin-right: 0.5vh;
    }
    .img1-bg {
        background-color: #ffefd1;
    }
    .img2-bg {
        background-color: #ffa800;
    }
}
.statistics {
    font-size: 2.23vh !important;
    margin-right: 0.7vh;
}

.typoFormat {
    color: #757575 !important;
    font-weight: 400;
    font-size: 1.98vh !important;
}
.image {
    width: 1.6vh;
    margin-right: 0.5vh;
}
.rowGap {
    gap: 2vw !important;
}
.continueSetup {
    margin-top: 4.3vh !important;
}

.imgOfSurvey {
    height: 25.75vh;
    width: 15.34vw;
}
.centerClass {
    margin: 5vh;
    .columnGap {
        gap: 5vh !important;
    }
    .btn {
        color: black !important;
        border: 1px solid black !important;
        font-size: 1.98vh !important;
        font-weight: 400 !important;
    }
    .img {
        height: 2.12vh;
        width: 1.04vw;
    }
}

.tableClass {
    .textClass {
        font-size: 1.98vh !important;
        line-height: 2.82vh !important;
    }
    .textLessSize {
        font-size: 1.98vh !important;
        line-height: 2.82vh !important;
    }
    .iconClass {
        width: 1rem !important;
        height: 1rem !important;
    }
    .imgsort {
        height: 2.26vh;
        width: 1.11vw;
        cursor: pointer;
    }
}

.reviewedColor {
    background-color: #defae6;
}
.underreviewcolor {
    background-color: #fff3ce;
}
.nonprofit {
    background-color: #0679c3;
}
.chipClass {
    display: flex;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    gap: 0.27vw;
    border-radius: 10px;
    .text {
        font-size: 1.68vh !important;
        font-weight: 500 !important;
        line-height: 2.24vh !important;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .reviewed {
        background-color: #defae6 !important;
    }
    .underreview {
        background-color: #fff3ce !important;
    }
    .nonprofit {
        color: #fff;
    }
}

.surveyStatusClass {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .text {
        font-size: 1.7vh !important;
        font-weight: 500 !important;
        line-height: 2.26vh !important;
    }
    .imgApprove {
        width: 12px;
        height: 12px;
    }

    .reviewed {
        background-color: #defae6 !important;
        color: #007f26 !important;
        padding: 0.28vh 0.55vw;
        border-radius: 10px;
    }
    .underreview {
        background-color: #fff3ce !important;
        color: #c58200 !important;
        padding: 0.28vh 0.55vw;
        border-radius: 10px;
    }
    .draft {
        background-color: #f2f2f2 !important;
        color: #757575 !important;
        padding: 0.28vh 0.55vw;
        border-radius: 10px;
    }
    .closed {
        background-color: #e6e6e6 !important;
        color: #333333 !important;
        padding: 0.28vh 0.55vw;
        border-radius: 10px;
    }
}

.progressBar {
    width: 11.11vw;
    height: 1.13vh;
    opacity: 0.20000000298023224;
    background: #8b8a88;
}

.theadClass {
}
.tbodyText {
    font-size: 1.98vh !important;
    line-height: 2.83vh !important;
}
.actionClass {
    font-size: 1.7vh !important;
    line-height: 2.26vh !important;
    cursor: pointer;
    letter-spacing: 0.6px;
}

.tblecellStyled {
    color: #757575 !important;
}

.imgIconClass {
    height: 1.7vh !important;
    width: 0.83vw !important;
}
.activeClass {
    border-bottom: 4px solid black;
}

.fnd {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2.83vh 1.67vw;
    border-radius: 8px;
    gap: 4.52vh;
    width: 31.94vw;
}

.next {
    position: absolute;
    right: 2.7vw;
    border-radius: 50%;
    background: #fff;
    padding: 0.8vh 0.6vw 0;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgba(5, 28, 44, 0.15), 0 8px 16px -1px rgba(5, 28, 44, 0.2);
    .img {
        width: 1.11vw;
        height: 2.26vh;
    }
}
.nextLive {
    right: -1.055vw !important;
    top: calc(50% - 1vh);
    transform: translateY(-50%);
}
.prevLive {
    top: calc(50% - 1vh);
    transform: translateY(-50%);
    left: -1.055vw !important;
}
.nextLive,
.prevLive {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5vw;
    height: 2.5vw;
    min-width: 30px;
    min-height: 30px;
    padding: 0 !important;

    .classFlex {
        display: flex;
    }
}
.prev {
    position: absolute;
    left: 15vw;
    border-radius: 50%;
    background: #fff;
    padding: 0.8vh 0.6vw 0;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgba(5, 28, 44, 0.15), 0 8px 16px -1px rgba(5, 28, 44, 0.2);
    .img {
        width: 1.11vw;
        height: 2.26vh;
    }
    .rotate {
        rotate: 180deg;
    }
}

.vertical-box-shadow {
    position: absolute;
    height: calc(100% - 2vh);
    width: 1px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    z-index: -1;
    right: 0;
    margin-bottom: 1vh;
}

.finalizeComponent {
    .MuiTooltip-tooltip {
        padding: 12px !important;
    }
}
