.section {
    .spanText {
        font-size: 3.39vh !important;
        line-height: 5.08vh !important;
        padding-bottom: 3vh;
    }
    .surveySecfontSize {
        font-size: 3.39vh !important;
        line-height: 3.95vh !important;
    }
    .textInfo {
        line-height: 3.38vh !important;
        font-size: 1.16vw !important;
    }
    .textClass {
        font-size: 1.98vh !important;
        line-height: 2.23vh !important;
        margin-top: 1px;
        margin-right: 0.7vw !important;
    }
    .imgClss {
        width: 1.5vw;
        height: 2.4vh;
    }
    .titleClass {
        padding-top: 3vh;
        padding-bottom: 2vh;
    }
}

.surveys {
    background: #f8f8f8;
    img.img {
        height: 3.39vh;
        width: 1.67vw;
    }
    .gapClass {
        align-self: stretch;
    }
    .flexType {
        align-items: flex-start !important;
    }
    .flexGap {
        gap: 2.22vw;
        &:first-child {
            font-size: 1.98vh !important;
            line-height: 3.07vh !important;
            color: #757575;
        }
    }
    .stepper {
        gap: 1.12vw;
        span {
            font-size: 2.26vh !important;
            font-weight: 300 !important;
            line-height: 3.69vh !important;
            padding-bottom: 0.56vh;
            border-bottom: 0.56vh solid transparent;
        }
        .active {
            border-bottom: 0.56vh solid #051c2c;
            font-size: 2.26vh !important;
            font-weight: 400 !important;
        }
    }
    .basicinfo {
        padding: 35vh 3.61vw 7.23vh 3.61vw;
        .radioimg {
            height: 2.83vh;
            width: 1.39vw;
        }
        .img {
            height: 2.84vh;
            width: 1.39vw;
        }
    }
}
.MuiFormHelperText-root {
    margin-left: 0px !important;
}
.dialogContent {
    align-items: center !important;
    & * {
        text-align: center;
    }
}

.column {
    padding: 5.6vh 2.8vw;
    border-bottom: 1px solid #e6e6e6;
}
.flexClass {
    padding: 0 2.8vw 5.6vh 2.8vw;
    align-items: flex-start;
    gap: 1.11vw;
}
.stretch {
    align-self: stretch !important;
    align-items: center !important;
}

.approved {
    background: #defae6;
}
.rejected {
    background: #fde7e7;
}
.pending {
    background: #fff3ce;
}
.total {
    background: #e6f2f9;
}
.notsubmitted {
    background-color: #f2f2f2;
}

.optionalQuestions {
    .mainContent {
        .MuiAccordion-root {
            border: 0 !important;
        }

        .MuiFormControlLabel-root {
            border-bottom: 1px solid #e6e6e6;

            &:first-child {
                border-color: #333333;
            }
            &:last-child {
                border: 0;
            }

            .MuiTypography-root {
                border-left: 1px solid #e6e6e6;
            }

            .MuiButtonBase-root {
                .img {
                    height: 1.8vh;
                    width: 1.8vh;
                }
                svg {
                    font-size: 2.52vh;
                    color: #000000;
                }
            }
        }
    }
}

.outcomesAndPractices {
    .mainContent {
        .MuiAccordion-root {
            border: 0 !important;
        }

        .MuiFormControlLabel-root {
            border-bottom: 1px solid #e6e6e6;

            &:first-child {
                border-color: #333333;
            }
            &:last-child {
                border: 0;
            }

            .MuiTypography-root {
                border-left: 1px solid #e6e6e6;
            }

            .MuiButtonBase-root {
                .img {
                    height: 1.8vh;
                    width: 1.8vh;
                }
                svg {
                    font-size: 2.52vh;
                    color: #000000;
                }
            }
        }
    }

    &.notPulse {
        .mainContent {
            .MuiFormControlLabel-root {
                .MuiButtonBase-root {
                    svg {
                        color: #b3b3b3;
                    }
                }
            }
        }
    }
}
