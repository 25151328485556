@font-face {
    font-family: 'Bower';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Bower-Bold.woff');
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Light.woff');
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-LightItalic.woff');
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Regular.woff');
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Italic.woff');
    font-weight: 400;
    font-stretch: normal;
    font-style: italic;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Medium.woff');
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-MediumItalic.woff');
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
}

html {
    height: 100vh;
    body {
        margin: 0;
        box-sizing: border-box;
        height: 100vh;
        // font-family: "Mckinsey Sans";
    }
    * {
        box-sizing: border-box;
    }
}

.MuiAutocomplete-popper {
    font-family: inherit;
    * {
        font-family: inherit;
    }
}

.full-width {
    width: 100%;
}
